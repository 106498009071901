import React, { useEffect } from "react";

import { TextBlock } from "../Components/TextBlock";
import { Disclaimer } from "../Components/Disclaimer";

import "./Impressum.css";
import "./Impressum13.css";
import "./Impressum8.css";

const Impressum = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [infoMail, setInfoMail] = React.useState("info@gesenkschmiede.de");
  return (
    <div className="impressum">
      <div className="impressum__header">
        <h3 className="impressum__header__h3">Impressum</h3>
      </div>
      <div className="impressum__info">
        <div className="impressum__info__item--1--1">
          <TextBlock title="Impressum">
            Angaben gemäß § 5 TMG:
            <br />
            <br />
            Ernst Schäfer Nachf. GmbH
            <br />
            Van-Meenen-Str. 23
            <br />
            Gesenkschmiede
            <br />
            42651 Solingen
            <br />
            <br />
            Telefon: +49 (0)212 202208
            <br />
            Telefax: +49 (0)212 202879
            <br />
            E-Mail:{" "}
            <a className="impressum__mail" href={`mailto:${infoMail}`}>
              {infoMail}
            </a>
          </TextBlock>
        </div>
        <div className="impressum__info__item--1--2">
          <TextBlock title="Vertretungsberechtigte Geschäftsführer:">
            Dr. Sebastian Schäfer, Hartmut Schäfer
            <br />
            <br />
            Registergericht: Amtsgericht Wuppertal:
            <br />
            Registernummer: HRB 14977
            <br />
            Umsatzsteuer-Identifikationsnummer gemäß
            <br />§ 27a Umsatzsteuergesetz: DE 120883271
          </TextBlock>
        </div>
        <div className="impressum__info__item--2--1">
          <TextBlock title="Online-Streitbeilegung">
            Die Europäische Kommission stellt unter{" "}
            <a
              className="impressum__link"
              href="//www.ec.europa.eu/consumers/odr"
            >
              www.ec.europa.eu/consumers/odr
            </a>{" "}
            eine Plattform zur Online-Streitbeilegung bereit, die Verbraucher
            für die Beilegung einer Streitigkeit nutzen können und auf der
            weitere Informationen zum Thema Streitschlichtung zu finden sind.
          </TextBlock>
        </div>
        <div className="impressum__info__item--2--2">
          <TextBlock title="Außergerichtliche Streitbeilegung">
            Wir sind weder verpflichtet noch dazu bereit, im Falle einer
            Streitigkeit mit einem Verbraucher an einem
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </TextBlock>
        </div>
        <div className="impressum__info__item--3--1">
          <TextBlock title="Fotos:">
            Georg Bortfeldt <br />
            <br />
            Shutterstock,{" "}
            <a className="impressum__link" href="//www.shutterstock.com">
              www.shutterstock.com
            </a>
          </TextBlock>
        </div>
      </div>
      <Disclaimer />
    </div>
  );
};

export default Impressum;
