import { createStore } from "redux";

const initialState = {
  shouldScroll: false,
  shouldScrollTop: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SCROLL-ON":
      return {
        ...state,
        shouldScroll: true
      };
    case "SCROLL-OFF":
      return {
        ...state,
        shouldScroll: false
      }
    case "TOGGLE-SCROLL-TOP":
      return {
        ...state,
        shouldScrollTop: !state.shouldScrollTop
      }
    default:
      return state;
  }
};

export const store = createStore(reducer);
