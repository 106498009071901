import React, { useState, useEffect } from "react";
import "./NavBar.css";
import "./NavBar10.css";
import "./NavBar8.css";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { scrollOn, toggleScrollTop } from "../actions";

function mapStateToProps(state) {
  return {
    shouldScroll: state.shouldScroll,
  };
}

const mapDispatchToProps = {
  scrollOn,
  toggleScrollTop,
};

const initialClasses = {
  home: ["nav-link"],
  unternehmen: ["nav-link"],
  leistung: ["nav-link"],
  produkte: ["nav-link"],
  referenzen: ["nav-link"],
  auszeichnungen: ["nav-link"],
  kontakt: ["nav-link"],
  jobs: ["nav-link"],
};

const NavBar = (props) => {
  const [classes, setClasses] = useState({
    ...initialClasses,
    home: [...initialClasses["home"], "nav-link--selected"],
  });
  const [navBarClasses, setNavBarClasses] = useState(["nav-bar"]);

  useEffect(() => {
    console.log(props.location.pathname);
    let key = props.location.pathname.substring(1).toLowerCase();
    if (key in initialClasses) {
      setClasses({
        ...initialClasses,
        [key]: [...initialClasses[key], "nav-link--selected"],
      });
    } else {
      setClasses({
        ...initialClasses,
        home: [...initialClasses.home, "nav-link--selected"],
      });
    }
  }, [props.location.pathname]);

  const toggleVisibility = () => {
    if (window.innerWidth <= 880) {
      const classN = "nav-bar__ul__li--visible";
      let newClasses = { ...classes };
      for (var key in newClasses) {
        const index = newClasses[key].indexOf(classN);
        index === -1
          ? newClasses[key].push(classN)
          : newClasses[key].splice(index, 1);
      }
      setClasses({
        ...newClasses,
      });
      toggleNavBarClasses();
    }
  };

  const toggleNavBarClasses = () => {
    if (navBarClasses.indexOf("nav-bar--opened") !== -1) {
      setNavBarClasses(["nav-bar"]);
    } else {
      setNavBarClasses(["nav-bar", "nav-bar--opened"]);
    }
  };

  return (
    <div className={navBarClasses.join(" ")}>
      <div className="nav-bar__menu" onClick={toggleVisibility}>
        ≡
      </div>
      <ul className="nav-bar__ul">
        <li className="nav-bar__ul__li">
          <Link
            className={classes.home.join(" ")}
            to="/Home"
            onClick={() => {
              props.toggleScrollTop();
              toggleVisibility();
            }}
          >
            HOME
          </Link>
        </li>
        <li className="nav-bar__ul__li">
          <Link
            className={classes.unternehmen.join(" ")}
            to="/Unternehmen"
            onClick={toggleVisibility}
          >
            UNTERNEHMEN
          </Link>
        </li>
        <li className="nav-bar__ul__li">
          <Link
            className={classes.leistung.join(" ")}
            to="/Leistung"
            onClick={toggleVisibility}
          >
            LEISTUNG
          </Link>
        </li>
        <li className="nav-bar__ul__li">
          <Link
            className={classes.produkte.join(" ")}
            to="/Produkte"
            onClick={toggleVisibility}
          >
            PRODUKTE
          </Link>
        </li>
        <li className="nav-bar__ul__li">
          <Link
            className={classes.referenzen.join(" ")}
            to="/Referenzen"
            onClick={toggleVisibility}
          >
            ANWENDUNG
          </Link>
        </li>
        <li className="nav-bar__ul__li">
          <Link
            className={classes.auszeichnungen.join(" ")}
            to="/Auszeichnungen"
            onClick={toggleVisibility}
          >
            AUSZEICHNUNGEN
          </Link>
        </li>
        <li className="nav-bar__ul__li" onClick={props.scrollOn}>
          <Link
            className={classes.kontakt.join(" ")}
            to="/Home"
            onClick={toggleVisibility}
          >
            KONTAKT
          </Link>
        </li>
        <li className="nav-bar__ul__li">
          <Link
            className={classes.jobs.join(" ")}
            to="/Jobs"
            onClick={toggleVisibility}
          >
            BEWERBEN
          </Link>
        </li>
      </ul>
    </div>
  );
};

const NavBarHOC = withRouter((props) => <NavBar {...props} />);
export default connect(mapStateToProps, mapDispatchToProps)(NavBarHOC);
