import React, { useEffect } from "react";

import { TextBlock } from "../Components/TextBlock";
import { Disclaimer } from "../Components/Disclaimer";

import iso9001Img from "../Assets/schaefer_ISO_9001.jpg";

import "./Auszeichnungen.css";
import "./Auszeichnungen13.css";
import "./Auszeichnungen8.css";

const Auszeichnungen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="auszeichnungen">
      <div className="auszeichnungen__header">
        <h3 className="auszeichnungen__header__h3">
          Auszeichnungen und Zertifikate
        </h3>
      </div>

      <div className="auszeichnungen__info">
        <div className="auszeichnungen__info__item--1--1">
          <TextBlock title="Kundenorientierung zahlt sich aus!">
            Wir stellen uns nicht nur den branchenüblichen Zertifizierungen,
            sondern lassen uns auch von der Qualitätsicherung unserer Kunden auf
            den Zahn fühlen. So gliedern wir uns in die Produktionskette und
            deren Anforderung ein. Wir erhalten regelmäßig Spitzenbewertungen
            durch unsere Geschäftspartner.
          </TextBlock>
        </div>
        <div className="auszeichnungen__info__item--1--2">
          {/* <TextBlock title="Zertifikat für Werkstoffhersteller.">
            Für besondere Anwendungen sind wir auch gemäß Druckgeräterichtlinie
            97/23/EG nach EN764-5, Abschnitt 4.2 und AD 2000 W0 (3.1
            Zertifizierung für Werkstoffhersteller) zertifiziert.
          </TextBlock> */}
        </div>
        <div className="auszeichnungen__info__item--2--1">
          <TextBlock title="Zertifikat ISO 9001:2015">
            Unsere Bemühungen werden dabei durch unser
            Qualitätsmanagement-System nach ISO 9001:2015 unterstützt und
            gelenkt. Liefertreue, Kundenberatung und maximale Flexibilität zur
            Erfüllung aller Kundenanforderungen zeichnen unseren
            inhabergeführten Familienbetrieb besonders aus.
          </TextBlock>
        </div>
        <div className="auszeichnungen__info__item--1--2">
          <img
            src={iso9001Img}
            alt="ISO9001"
            className="auszeichnungen__info__item--2--2__img"
          />
        </div>
      </div>
      <Disclaimer />
    </div>
  );
};

export default Auszeichnungen;
