import React, { useRef, useEffect } from "react";
import produkte1Img from "../Assets/produkte1.png";
import logo from "../Assets/logo.JPG";
import fertigungImg from "../Assets/fertigung5.png";
import logistik1Img from "../Assets/logistik1.png";
import fertigung2Img from "../Assets/fertigung.png";
import unternehmenImg from "../Assets/unternehmen.png";

import "./Home.css";
import "./Home13.css";
import "./Home10.css";
import "./Home8.css";
import InfoCard from "../Components/InfoCards";
import { connect } from "react-redux";
import { scrollOn, scrollOff, toggleScrollTop } from "../actions";
import { Disclaimer } from "../Components/Disclaimer";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    shouldScroll: state.shouldScroll,
    shouldScrollTop: state.shouldScrollTop,
  };
};

const mapDispatchToProps = {
  scrollOn,
  scrollOff,
  toggleScrollTop,
};

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const Home = (props) => {
  const { shouldScroll, scrollOff, shouldScrollTop, toggleScrollTop } = props;
  useEffect(() => {
    if (shouldScrollTop) {
      window.scrollTo(0, 0);
      toggleScrollTop();
    }
  }, [shouldScrollTop, toggleScrollTop]);
  const disclaimerRef = useRef(null);

  const executeScroll = () => {
    scrollToRef(disclaimerRef);
  };
  useEffect(() => {
    if (shouldScroll) {
      executeScroll();
      scrollOff();
    }
  }, [shouldScroll, scrollOff]);

  return (
    <div className="homepage">
      <div className="homepage__background">
        <img
          className="homepage__background__img"
          src={produkte1Img}
          alt="Background"
        />
        <div className="homepage__background__description">
          <h3 className="homepage__background__description__name">
            Gesenkschmiede
          </h3>
          <h3 className="homepage__background__description__name">
            Ernst Schäfer Nachf. GmbH
          </h3>
          <p className="homepage__background__description__p">
            Enge Zusammenarbeit, kurze Kommunikationswege und dynamische Planung
            machen uns zu einem starken Partner für anspruchsvolle
            Schmiedeteile.
          </p>
          <div className="homepage__background__description__buttons">
            <Link
              to="/Unternehmen"
              className="homepage__background__description__button"
            >
              Erfahren Sie mehr
            </Link>
            <Link
              to="/Home"
              onClick={executeScroll}
              className="homepage__background__description__button"
            >
              Kontakt aufnehmen
            </Link>
          </div>
        </div>
      </div>

      <div className="homepage__infocards">
        <div className="homepage__infocards__infocard">
          <Link
            to="/Unternehmen"
            className="homepage__infocards__link"
            style={{ textDecoration: "none" }}
          >
            <InfoCard image={unternehmenImg} title={"Unternehmen"}>
              Als inhabergeführtes Familienunternehmen legen wir Wert auf
              Qualität, Liefertreue und die Erfüllung aller Kundenwünsche.
            </InfoCard>
          </Link>
        </div>
        <div className="homepage__infocards__infocard">
          <Link
            to="/Leistung"
            className="homepage__infocards__link"
            style={{ textDecoration: "none" }}
          >
            <InfoCard image={logistik1Img} title={"Leistung"}>
              Ein moderner Werkzeugbau, eine leistungsfähige Schmiede und eine
              effiziente Logistik garantieren Ihre Zufriedenheit.
            </InfoCard>
          </Link>
        </div>
        <div className="homepage__infocards__infocard">
          <Link
            to="/Produkte"
            className="homepage__infocards__link"
            style={{ textDecoration: "none" }}
          >
            <InfoCard image={fertigung2Img} title={"Produkte"}>
              Präzise und hochbelastbare Gesenkschmiedeteile produzieren wir aus
              Edelstahl, Stahl und individuellen Sonderlegierungen.
            </InfoCard>
          </Link>
        </div>
        <div className="homepage__infocards__infocard">
          <Link
            to="/Referenzen"
            className="homepage__infocards__link"
            style={{ textDecoration: "none" }}
          >
            <InfoCard image={fertigungImg} title={"Anwendung"}>
              Den weltweiten Einsatz unserer vielseitigen Produkte vom Schiffbau
              bis zur modernen Energietechnik realisieren wir mit der
              Spezialisierung auf Hydraulik und Maschinenbau.
            </InfoCard>
          </Link>
        </div>
      </div>

      <div className="homepage__intro">
        <div className="homepage__intro__item--1">
          <img src={logo} alt="logo" />
        </div>
        <div className="homepage__intro__item--2">
          <div className="homepage__intro__item--1--1">
            Schnelle und effektive Zusammenarbeit mit unseren Kunden garantieren
            langfristige und werschöpfende Beziehungen. Direkte Ansprechpartner,
            schnelle Unterstützung sowie hohe Kompetenz sind für uns
            selbstverständlich. So können wir uns um Ihre individuellen Wünsche
            und Ansprüche kümmern.
          </div>
          <br />
          <div className="homepage__intro__item--1--2">
            Profitieren Sie von unserem kompetenten Team, welches Ihnen von der
            Idee bis zur Lieferung zur Verfügung steht. Nehmen Sie gerne direkt
            Kontakt mit unseren Ansprechpartnern auf. Wir freuen uns auf Ihre
            Herausforderungen!
          </div>
        </div>
      </div>

      <div ref={disclaimerRef}>
        <Disclaimer />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
