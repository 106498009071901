import React from "react";

import { TextBlock } from "../TextBlock";

import werkzeugbau3Img from "../../Assets/werkzeugbau3.png";

import "./Werkzeugbau.css";
import "./Werkzeugbau13.css";
import "./Werkzeugbau8.css";

export const Werkzeugbau = () => {
  return (
    <div className="werkzeugbau">
      <h3 className="werkzeugbau__head">Werkzeugbau</h3>
      <div className="werkzeugbau__textblock--1">
        <TextBlock title="Flexibilität ist durch unseren eigenen Werkzeugbau garantiert.">
          Auf unseren modernen drei- und fünfachs High-Speed-Fräsmaschinen
          fertigen wir unsere Schmiede- und Abgratwerkzeuge mit geringsten
          Fertigungstoleranzen bei gleichbleibender Wiederholgenauigkeit auf
          höchstem Niveau. Flexibel, unabhängig und leistungsstark stellt sich
          unser Werkzeugbau ganz in den Dienst des Kunden, damit eine fach- und
          termingerechte Erstellung aller Werkzeuge als Grundvoraussetzung einer
          reibungslosen Fertigung gewährleistet ist. In der Fertigung
          tausendfach bewährte Werkzeuge führen zu passgenauen, robusten und
          hochwertigen Schmiedestücken.
        </TextBlock>
      </div>
      <img
        src={werkzeugbau3Img}
        alt="Cad/Cam"
        className="werkzeugbau__img--1"
      />
      <div className="werkzeugbau__textblock--2">
        <TextBlock title="Eigene Konstruktion im Haus.">
          Der Vorteil liegt auf der Hand: bei Verbesserungen von Teilen können
          Änderungen in der Konstruktion sofort umgesetzt werden. Das Know-how
          vom Schmieden fließt direkt in die Konstruktion ein.
        </TextBlock>
      </div>
      <div className="werkzeugbau__textblock--3">
        <TextBlock title="Wir sind flexibel.">
          Weitgehend unabhängig von externen Dienstleistern können wir
          unmittelbar auf Kundenwünsche eingehen. Ob nun Neuteile oder
          Modifikationen von Produkten, durch die enge Verzahnung mit der
          Konstruktion haben wir oft ein leichtes Spiel mit neuen
          Herausforderungen.
        </TextBlock>
      </div>
    </div>
  );
};
