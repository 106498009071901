import React, { useEffect } from "react";

import { Header } from "../Components/Header";
import { Disclaimer } from "../Components/Disclaimer";
import { TextBlock } from "../Components/TextBlock";

import offshoreImg from "../Assets/offshore.jpg";
import shiffImg from "../Assets/schiff.jpg";

import "./Referenzen.css";
import "./Referenzen8.css";

const Referenzen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="referenzen">
      <Header
        title="Der weltweite Einsatz unserer Schmiedeteile sorgt für Produkte auf höchten Niveau."
        image={offshoreImg}
        imageAlt={"Referenzen"}
      />

      <div className="referenzen__info">
        <div className="referenzen__info__item--1--1">
          <TextBlock title="Gefertigte Schäfer-Schmiedestücke weltweit im Einsatz.">
            Unsere Schmiedeteile werden in den unterschiedlichsten
            Anwendungsbereichen erfolgreich verwendet - von der klassischen
            bergischen Werkzeugindustrie über den Einsatz mechanisch
            hochbelastbarer Schmiedestücke in Elektrowerkzeugen, im
            Maschinenbau, im Baugewerbe, im Verkehrswesen, der chemischen
            Industrie, der Lebensmittelindustrie sowie der Energietechnik und
            dem Schiffsbau. Überall, wo passgenaue, hochqualitative und
            hochbelastbare Bauteile benötigt werden, wenden unsere Kunden im
            Gesenk gefertigte Schäfer-Schmiedstücke an.
          </TextBlock>
        </div>
        <div className="referenzen__info__item--1--2">
          <TextBlock title="Schiffsbau: Einsatz von Schmiedeteilen im klimatisch anspruchsvollen Seeklima.">
            Die dynamische Branche erfordert nicht nur flexible zuverlässige
            Partner, sondern auch die unterschiedlichsten Bauteile in den
            verschiedensten Werkstoffen: Von Stahl und Edelstahl über Messing-
            bis hin zu Kupferlegierungen liefern wir dazu die notwendigen
            Schmiedeteile. Für jede Anwendung das passende Schmiedestück im
            anforderungsgerechten Material - Qualität die sich auszahlt.
          </TextBlock>
        </div>
        <div className="referenzen__info__item--2--1">
          <TextBlock title="Die Energieversorgung von morgen ist die Herausforderung von heute. Wir arbeiten mit an der Lösung - mit unseren Schmiedeteilen!">
            Hochbelastbare Bauteile, klimagerechtes Material und vielfältiger
            Einsatz bei absoluter Zuverlässigkeit - die Anforderungen an jedes
            Bauteil sind besonders hoch, da ein Versagen schon kleinster
            Elemente verheerende Folgen, nicht nur für die
            Versorgungssicherheit, sondern im Besonderen auch für die Umwelt
            haben kann.
          </TextBlock>
        </div>
        <div className="referenzen__info__item--2--2">
          <img
            src={shiffImg}
            alt="Referenzen"
            className="referenzen__info__item--2--2__img"
          />
        </div>
      </div>

      <Disclaimer />
    </div>
  );
};

export default Referenzen;
