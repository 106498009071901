import React from "react";

import { TextBlock } from "../TextBlock";

import "./CAD.css";
import "./CAD13.css";
import "./CAD8.css";

import cadCamImg from "../../Assets/cadCam1.png";

export const CAD = () => {
  return (
    <div className="cad">
      <h3 className="cad__head">CAD / CAM Entwicklung</h3>
      <div className="cad__textblock--1">
        <TextBlock title="Ganzheitlich denken.">
          Mit fortschrittlicher 3D-CAD Technik erstellen wir sämtliche
          Fertigungswerkzeuge mit höchster Präzision in kürzester Zeit. Als
          Ausgangsmaterial genügt eine CAD-Datei, eine Zeichnung, ein Muster
          oder auch nur die Idee eines Produktes. Daraus entwickeln wir alle
          notwendeigen Fertigungswerkzeuge im eigenen Haus.
        </TextBlock>
      </div>
      <img src={cadCamImg} alt="Cad/Cam" className="cad__img--1"/>
      <div className="cad__textblock--2">
        <TextBlock title="Fortschrittlich.">
          Von der Idee zum Schmiedeteil: Bei den Berechnungen zur Erstellung der
          Werkzeuge müssen alle notwendigen Parameter, die bei der Fertigung
          Einfluss auf das herzustellende Teil haben, berücksichtigt werden.
          Gerade bei den komplexen Vorgängen des Schmiedens von Edelstahl sind
          die Anforderungen hoch. So gewinnt bei einem hohen Qualitätsanspruch
          die Erfahrung immer mehr an Gewicht, denn vieles ist nicht
          ausschließlich durch moderne Technologie zu erreichen: sondern durch
          Tradition erhaltene Erfahrung.
        </TextBlock>
      </div>
      <div className="cad__textblock--3">
        <TextBlock title="Mit Beratungsleistung.">
          Bei der Erstellung von Erstwerkzeugen können wir schnell reagieren und
          so optimal einen reibungslosen Produktionszyklus starten.
          Ersatzwerkzeuge und fertigungsbegleitende Werkzeugpflege
          hochqualitativ bei fortwährend schneller Verfügbarkeit.
        </TextBlock>
      </div>
    </div>
  );
};
