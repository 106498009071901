import React, { useEffect } from "react";
import "./Unternehmen.css";
import "./Unternehmen8.css";
import fertigung2Img from "../Assets/fertigung2.png";
import werkzeugbauImg from "../Assets/werkzeugbau.png";
import { Disclaimer } from "../Components/Disclaimer";
import { Header } from "../Components/Header";
import { TextBlock } from "../Components/TextBlock";
import logo from "../Assets/logo.JPG";

const Unternehmen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <div className="unternehmen">
        <Header
          title="Edelstahl zu schmieden gehört zu den anspruchsvollen Aufgaben einer
            Gesenkschmiede."
          image={fertigung2Img}
          imageAlt={"Fertigung"}
        />
        <div className="unternehmen__info">
          <div className="unternehmen__info__item--1--1">
            <TextBlock title="Wir stellen uns dieser Herausforderung.">
              In mehr als 80 Jahren konnten wir uns zu einer der führenden Stahl
              und Edelstahl Schmieden mit Stückgewichten von bis zu 5.000g
              entwickeln. Wir als inhabergeführtes Familienunternehmen legen
              Wert auf Liefertreue, Kundenberatung sowie die Motivation zur
              Erfüllung aller Kundenwünsche. Von Anfang an stehen wir unseren
              Kunden mit unseren Know–how zur Verfügung. Das
              Qualitätsmangement-System nach ISO 9001:2015 lenkt unsere absolute
              Kundenorientierung seit Jahren.
            </TextBlock>
          </div>
          <div className="unternehmen__info__item--1--2">
            <TextBlock title="Ansprüche an Qualität und Wirtschaftlichkeit.">
              Die eigene 3D-CAD Konstruktion in Verbindung mit unserem CAM
              gesteuerten Werkzeugbau ermöglicht eine kurze Reaktionszeit zur
              Auftragserfüllung und eine dauerhafte Schmiedequalität auf
              höchstem Niveau. Von diesem Anspruch an Qualität und
              Wirtschaftlichkeit profitieren unsere nationalen und
              internationalen Kunden.
            </TextBlock>
          </div>
          <div className="unternehmen__info__item--2--1">
            <img
              className="unternehmen__info__item--2--1__img"
              src={werkzeugbauImg}
              alt="Entstehung"
            />
          </div>
          <div className="unternehmen__info__item--2--2">
            <TextBlock title="Moderner Werkzeugbau: Qualität auf höchstem Niveau.">
              Traditionelle Schmiedetechnik in Verbindung mit
              Fertigungswerkzeugen höchster Güte ermöglichen die kostengünstige
              Fertigung verschiedenster Werkstoffe. Unser moderner Werkzeugbau
              ist zugleich Herzstück und Grundlage einer exzellenten Fertigung.
              Vom kleinsten bis zum größten Rohling: Durchgehend Qualität auf
              höchstem Niveau.
            </TextBlock>
          </div>
          <div className="unternehmen__info__item--3--1">
            <TextBlock title="Schmieden hat Tradition.">
              1932 gegründet, schmieden wir nunmehr seit über 80 Jahren in der
              vierten Generation Produkte unterschiedlichster Anwendungen mit
              höchsten Ansprüchen an Qualität und Wirtschaftlichkeit. Zum
              Einsatz kommen dabei sieben Schmiedehämmer, mit denen ein
              Gewichtsbereich von 30g bis ca. 5.000g abgedeckt wird.
            </TextBlock>
          </div>
          <div className="unternehmen__info__item--3--2">
            <img
              className="unternehmen__info__item--3--2__img"
              src={logo}
              alt="Logo"
            />
          </div>
        </div>
      </div>
      <Disclaimer />
    </div>
  );
};

export default Unternehmen;
