import React from "react";
import "./Disclaimer.css";
import "./Disclaimer13.css";
import "./Disclaimer8.css";

import { Link } from "react-router-dom";

export const Disclaimer = () => {
  const [infoMail, setInfoMail] = React.useState("info");
  const [sschaeferMail, setSschaeferMail] =
    React.useState("sebastian.schaefer");
  const [schaeferMail, setSchaeferMail] = React.useState("schaefer");
  const [neckermannMail, setNeckermannMail] = React.useState("neckermann");
  const [groppMail, setGroppMail] = React.useState("gropp");
  const [seweliesMail, setSeweliesMail] = React.useState("sewelies");
  const [mailProvider, setMailProvider] = React.useState("@gesenkschmiede");
  const [mailEnding, setMailEnding] = React.useState(".de");

  return (
    <div className="disclaimer">
      <div>
        <div className="disclaimer__kontakt">
          <p className="disclaimer__kontakt__header">Kontakt</p>
          <br />
          <p>Ernst Schäfer Nachf. GmbH</p>
          <p>Gesenkschmiede</p>
          <p>Van-Meenen-Str. 23</p>
          <p>42651 Solingen</p>
          <br />
          <p>Telefon: 0212 202208</p>
          <p>Telefax: 0212 202879</p>
          <p>
            E-Mail:{" "}
            <a
              className="disclaimer__mail"
              href={`mailto:${infoMail + mailProvider + mailEnding}`}
            >
              {infoMail + mailProvider + mailEnding}
            </a>
          </p>
        </div>
        <div className="disclaimer__links">
          <Link
            to="/Impressum"
            className="disclaimer__link disclaimer__link--impressum"
          >
            Impressum
          </Link>
          <Link
            to="/Datenschutz"
            className="disclaimer__link disclaimer__link--datenschutz"
          >
            Datenschutz
          </Link>
        </div>
      </div>
      <div className="disclaimer__ansprechpartner">
        <p className="disclaimer__ansprechpartner__header">Ansprechpartner</p>
        <div className="disclaimer__ansprechpartner--item--0">
          <p className="disclaimer__ansprechpartner--name">
            Dr. Sebastian Schäfer
          </p>
          <p>Geschäftsführer, Einkauf, Verkauf</p>
          <p>
            E-Mail:{" "}
            <a
              className="disclaimer__mail"
              href={`mailto:${sschaeferMail + mailProvider + mailEnding}`}
            >
              {sschaeferMail + mailProvider + mailEnding}
            </a>
          </p>
        </div>
        <div className="disclaimer__ansprechpartner--item--1">
          <p className="disclaimer__ansprechpartner--name">Hartmut Schäfer</p>
          <p>Geschäftsführer, Einkauf, Verkauf</p>
          <p>
            E-Mail:{" "}
            <a
              className="disclaimer__mail"
              href={`mailto:${schaeferMail + mailProvider + mailEnding}`}
            >
              {schaeferMail + mailProvider + mailEnding}
            </a>
          </p>
        </div>
        <div className="disclaimer__ansprechpartner--item--2">
          <p className="disclaimer__ansprechpartner--name">
            Andreas Neckermann
          </p>
          <p>Betriebsleiter, QS, Konstruktion, Entwicklung</p>
          <p>
            E-Mail:{" "}
            <a
              className="disclaimer__mail"
              href={`mailto:${neckermannMail + mailProvider + mailEnding}`}
            >
              {neckermannMail + mailProvider + mailEnding}
            </a>
          </p>
        </div>
        <div className="disclaimer__ansprechpartner--item--3">
          <p className="disclaimer__ansprechpartner--name">
            Birgit Gropp-Gülicher
          </p>
          <p>Sekretariat, Einkauf, Verkauf</p>
          <p>
            E-Mail:{" "}
            <a
              className="disclaimer__mail"
              href={`mailto:${groppMail + mailProvider + mailEnding}`}
            >
              {groppMail + mailProvider + mailEnding}
            </a>
          </p>
        </div>
        <div className="disclaimer__ansprechpartner--item--4">
          <p className="disclaimer__ansprechpartner--name">Susanne Sewelies</p>
          <p>Buchhaltung</p>
          <p>
            E-Mail:{" "}
            <a
              className="disclaimer__mail"
              href={`mailto:${seweliesMail + mailProvider + mailEnding}`}
            >
              {seweliesMail + mailProvider + mailEnding}
            </a>
          </p>
        </div>
      </div>
      {/* <div className="disclaimer__links">
        <Link
          to="/Impressum"
          className="disclaimer__link disclaimer__link--impressum"
        >
          Impressum
        </Link>
        <Link
          to="/Datenschutz"
          className="disclaimer__link disclaimer__link--datenschutz"
        >
          Datenschutz
        </Link>
      </div> */}
    </div>
  );
};
