import React, { useEffect } from "react";

import { TextBlock } from "../Components/TextBlock";
import { Disclaimer } from "../Components/Disclaimer";

import "./Jobs.css";
import "./Jobs13.css";
import "./Jobs8.css";

const Jobs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="jobs">
      <div className="jobs__header">
        <h3 className="jobs__header__h3">Bewerben Sie sich jetzt!</h3>
        <p className="jobs__header__subtitle">Wir suchen neue Mitarbeiter.</p>
      </div>

      <div className="jobs__info">
        {/* <div className="jobs__info__item--1--1">
          <TextBlock title="Buchhalter (m/w/d)">
            Wir suchen ab sofort eine/n Miterabeiter/in für unsere Buchhaltung.
            Nach einer kurzen Einarbeitungszeit durch unsere Buchhalterin
            übernehmen Sie schnell eigenständig Aufgaben wie:
            <ul className="jobs__ul">
              <li>Zahlungen</li>
              <li>Kassen- und Bankbuchhaltung</li>
              <li>Zeiterfassung</li>
              <li>Lohnabrechnungen</li>
              <li>Krankenkassenmeldungen</li>
              <li>Urlaubsanträge</li>
              <li>Lohnsteuer</li>
            </ul>
            Unser Team wird Sie so lange begleiten, bis Sie alle Aufgaben
            vollständig beherrschen und somit unsere Hauptbuchhaltung übernehmen
            können. Als Familienunternehmen sind uns kurze Kommunikationswege
            und Flexibilität besonders wichtig. Dies bringt folgende Erwartungen
            an Sie mit:
            <ul className="jobs__ul">
              <li>Sie beherrschen die deutsche Sprache in Wort und Schrift</li>
              <li>
                Mehrjährige Berufserfahrung in der Buchhaltung, idealerweise
                zusätzlich im Personalwesen
              </li>
              <li>
                Sie beherrschen MSOffice sowie typische Buchhaltungssoftware
              </li>
              <li>Sie arbeiten gerne und effektiv selbstständig</li>
              <li>Hohes Verantwortungsbewusstsein</li>
              <li>Gute Team- und Kommunikationsfähigkeit</li>
              <li>
                Sie sind bereit, laufend neue Prozesse zu erlernen und neue
                Aufgaben zu übernehmen
              </li>
            </ul>
            Um Sie bei dieser anspruchsvollen Aufgabe zu unterstützen, bieten
            wir ein hervorragendes Arbeitsumfeld:
            <ul className="jobs__ul">
              <li>
                Keine Hierarchien: Als kleines Team arbeitet jede Abteilung eng
                zusammen. Hierbei achten wir immer auf ein lockeres und
                angenehmes Betriebsklima!
              </li>
              <li>
                Volle Entfaltungsfreiheit: Sie haben einen
                Verbesserungsvorschlag, der Sie oder unser Unternehmen
                effektiver arbeiten lässt? Gemeinsam werden wir den Vorschlag
                prüfen und sofort umsetzen.
              </li>
              <li>Ein langfristiger Arbeitsplatz</li>
              <li>Weiterbildungsangebote</li>
              <li>Adäquate Vergütung</li>
            </ul>
            Sie passen in unser Bewerberprofil und interessieren sich für die
            Stelle? Dann freuen wir uns auf Ihre Bewerbung! Bitte senden Sie
            Ihren Lebenslauf und ein kurzes Motivationsschreiben per Mail an
            info@gesenkschmiede.de.
          </TextBlock>
        </div> */}
        <div className="jobs__info__item--1--2">
          <TextBlock title="Gesenkschmied/in (m/w/d)">
            Wir suchen ab sofort mehrere Gesenkschmiede/innen. Das
            Gesenkschmieden ist ein traditionsreicher Handwerksberuf und das
            Zentrum unserer Produktion. Nach Einarbeitung durch unsere
            Schmiedeleitung sowie Schmiedekollegen erwarten Sie folgende
            Aufgaben:
            <ul className="jobs__ul">
              <li>Schmieden am Riemenfallhammer mit Automatiksteuerung</li>
              <li>Rüsten der Schmiedehämmer</li>
              <li>Bedienen der Durchlauföfen</li>
              <li>
                Befördern von Schmiederohlingen vor und nach der Schmiedung mit
                dem Hubwagen
              </li>
            </ul>
            Sie sollten mitbringen:
            <ul className="jobs__ul">
              <li>Gute Deutschkenntnisse</li>
              <li>Einsatzbereitschaft, Verlässlichkeit</li>
              <li>Sorgfalt und Genauigkeit bei der Arbeit</li>
              <li>Guter Umgang mit Kollegen</li>
              <li>Bereitschaft zur Schichtarbeit (keine Nachtschicht)</li>
            </ul>
            Gerne möchten wir die oben gennanten Fähigkeiten und Eigenschaften
            fördern und Sie bei Ihrer Berufsentwicklung mit anspruchsvollen
            Aufgaben unterstützen. Dafür bieten wir:
            <ul className="jobs__ul">
              <li>Ein hilfsbereites Team</li>
              <li>Attraktive Bezahlung</li>
              <li>Langfristiger Arbeitsplatz</li>
              <li>Persönliche Weiterentwicklung</li>
              <li>
                Regelmäßigen Kontakt und Hilfestellung bei Problemen durch die
                Personalleitung und Geschäftsführung
              </li>
              <li>Sonderzahlungen</li>
              <li>Getränkeversorgung</li>
            </ul>
            Sie passen in unser Bewerberprofil und interessieren sich für die
            Stelle? Dann freuen wir uns auf Ihre Bewerbung! Bitte melden Sie
            sich mit einer E-Mail an info@gesenkschmiede.de oder rufen Sie uns
            einfach an: 0212 202208.
          </TextBlock>
        </div>
        {/* <div className="jobs__info__item--2--1">
          <TextBlock title="Versand- und Lagermitarbeiter (m/w/d)">
            Wir suchen ab sofort einen Mitarbeiter/in für den Versand,
            Lagerarbeiten und Auslierungs- sowie Beschaffungsfahrten. Sie
            schließen sich einem Team aus 4-6 Mitarbeitern an, die Sie schnell
            einarbeiten und alle Prozesse zeigen. Diese Aufgaben erwarten Sie:
            <ul className="jobs__ul">
              <li>
                Verpacken von Waren auf Paletten, in Behältern oder Gitterboxen
              </li>
              <li>Be- und Entladen von LKW</li>
              <li>Kontrolle der Ein- und Ausgangswaren</li>
              <li>
                Buchen der Warenein- und ausgänge im Warenwirtschaftssystem
              </li>
              <li>Kontrolle der Liefermengen und -papiere</li>
              <li>Sonderlieferfahrten</li>
            </ul>
            Sie sollten mitbringen:
            <ul className="jobs__ul">
              <li>Sehr gute Deutschkenntnisse</li>
              <li>
                Einsatzbereitschaft, Multitaskingfähigkeit, Verlässlichkeit
              </li>
              <li>Hohe Effizienz unter Zeitdruck</li>
              <li>Teamfähigkeit</li>
              <li>
                Den Wunsch, stetig mehr Verantwortung und die stellvertretende
                Lagerleitung zu übernehmen
              </li>
              <li>Ein Führerschein ist von großem Vorteil</li>
            </ul>
            Gerne möchten wir die oben gennanten Fähigkeiten und Eigenschaften
            fördern und Sie bei Ihrer Berufsentwicklung mit anspruchsvollen
            Aufgaben unterstützen. Dafür bieten wir:
            <ul className="jobs__ul">
              <li>Ein hilfsbereites Team</li>
              <li>Langfristiger Arbeitsplatz</li>
              <li>Aufstiegschancen</li>
              <li>Adäquate Bezahlung</li>
              <li>Persönliche Weiterentwicklung</li>
              <li>Getränkeversorgung</li>
              <li>
                Regelmäßigen Kontakt und Hilfestellung bei Problemen durch die
                Personalleitung und Geschäftsführung
              </li>
              <li>Sonderzahlungen</li>
            </ul>
            Sie passen in unser Bewerberprofil und interessieren sich für die
            Stelle? Dann freuen wir uns auf Ihre Bewerbung! Bitte melden Sie
            sich mit einer E-Mail an info@gesenkschmiede.de oder rufen Sie uns
            einfach an: 0212 202208.
          </TextBlock>
        </div> */}
      </div>
      <Disclaimer />
    </div>
  );
};

export default Jobs;
