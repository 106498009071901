import React from "react";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import NavBar from "./NavBar";
import Home from "../src/Home/index";
import Unternehmen from "./Unternehmen";
import Leistung from "./Leistung";
import Produkte from "./Produkte";
import Referenzen from "./Referenzen";
import Auszeichnungen from "./Auszeichnungen";
import Impressum from "./Impressum";
import Datenschutz from "./Datenschutz";
import Jobs from "./Jobs";

const Routes = () => {
  return (
    <HashRouter>
      <div>
        <NavBar />
        <Switch>
          <Route exact path="/Home" component={Home} />
          <Route exact path="/Unternehmen" component={Unternehmen} />
          <Route exact path="/Leistung" component={Leistung} />
          <Route exact path="/Produkte" component={Produkte} />
          <Route exact path="/Referenzen" component={Referenzen} />
          <Route exact path="/Auszeichnungen" component={Auszeichnungen} />
          <Route exact path="/Impressum" component={Impressum} />
          <Route exact path="/Datenschutz" component={Datenschutz} />
          <Route exact path="/Jobs" component={Jobs} />
          <Route path="/*">
            <Redirect to="/Home" />
          </Route>
        </Switch>
      </div>
    </HashRouter>
  );
};

export default Routes;
