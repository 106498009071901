import React from "react";

import "./TextBlock.css";
import "./TextBlock8.css";

export const TextBlock = props => {
  return (
    <div className="text-block">
      <p className="text-block__title">{props.title}</p>
      <p className="text-block__text">
        {props.children}
      </p>
    </div>
  );
};
