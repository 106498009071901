import React from "react";
import "./InfoCard.css";

const InfoCard = (props) => {
  return (
    <div className="info-card">
      <p className="info-card__title">{props.title}</p>
      <img className="info-card__img" src={props.image} alt="Inside" />
      <p className="info-card__text">{props.children}</p>
    </div>
  );
};

export default InfoCard;
