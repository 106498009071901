import React from "react";

import "./Header.css";
import "./Header8.css";

export const Header = props => {
  return (
    <div className="header">
      <h3 className="header__h3">{props.title}</h3>
      <img className="header__img" src={props.image} alt={props.imageAlt} />
    </div>
  );
};
