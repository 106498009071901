import React from "react";

import { TextBlock } from "../TextBlock";

import fertigung2Img from "../../Assets/fertigung2.png";
import fertigung4Img from "../../Assets/fertigung4.png";
import fertigung5Img from "../../Assets/fertigung5.png";

import "./Fertigung.css";
import "./Fertigung13.css";
import "./Fertigung8.css";

export const Fertigung = () => {
  return (
    <div className="fertigung">
      <h3 className="fertigung__head">Fertigung</h3>
      <div className="fertigung__textblock--1">
        <TextBlock title="Flexible Möglichkeiten.">
          Der Einsatz der Gasöfen zur Erwärmung der Werkstücke ist flexibel. Je
          nach Werkstoff, Durchmesser oder Materiallängen, werden die optimalen
          Agregate ausgewählt. Auf sieben Hämmern fertigen wir kostengünstig
          Schmiederohlinge mit einem Einzelteilgewicht von 30g bis ca. 5.000g.
          Unsere Fertigung ist optimiert auf Qualität und Flexibilität – von den
          Schmiedeöfen über die Hämmer und Schmiedegesenke bis hin zu den
          Abgratwerkzeugen. Da alle Fertigungseinrichtungen und Werkzeuge
          weitgehend miteinander kombinierbar sind, erreichen wir herausragend
          flexible Fertigungsmöglichkeiten zur Erfüllung der Kundenwünsche.
        </TextBlock>
      </div>
      <img src={fertigung2Img} alt="Cad/Cam" className="fertigung__img--1" />
      <img src={fertigung4Img} alt="Cad/Cam" className="fertigung__img--2" />
      <div className="fertigung__textblock--2">
        <TextBlock title="Unsere Vorteile.">
          Unsere Gasöfen sind flexibel einsetzbar zur Erwärmung
          unterschiedlichster Werkstoffe, verschiedener Durchmesser und
          Materiallängen. Unsere hochpräzisen Werkzeuge sind aus eigener
          Konstruktion und Fertigung.
        </TextBlock>
      </div>
      <div className="fertigung__textblock--3">
        <TextBlock title="Wir sind flexibel.">
          Weitgehend unabhängig von externen Dienstleistern können wir
          unmittelbar auf Kundenwünsche eingehen. Ob nun Neuteile oder
          Modifikationen von Produkten, durch die enge Verzahnung mit der
          Konstruktion haben wir oft ein leichtes Spiel mit neuen
          Herausforderungen.
        </TextBlock>
      </div>
      <img src={fertigung5Img} alt="Cad/Cam" className="fertigung__img--3" />
    </div>
  );
};
