import React from "react";

import { TextBlock } from "../TextBlock";

import logistik3Img from "../../Assets/logistik3.png";
import logistik4Img from "../../Assets/logistik4.png";
import produkte3Img from "../../Assets/produkte3.png";

import "./Logistik.css";
import "./Logistik13.css";
import "./Logistik8.css";

export const Logistik = () => {
  return (
    <div className="logistik">
      <h3 className="logistik__head">Logistik</h3>
      <div className="logistik__textblock--1">
        <TextBlock title="Wir sind zentral gelegen.">
          Im Herzen von Europa ansässig, nutzen wir die Vorteile dieser
          zentralen Lage in Verbindung mit der hervorragenden Infrastruktur
          unseres Landes zur zeitnahen und termingerechten Belieferung unserer
          Geschäftspartner. Hersteller sowie Service für unsere
          Produktionsanlagen befinden sich in nächster Nähe und sichern eine
          größtmögliche, störungsfreie Verfügbarkeit unserer Fertigung.
        </TextBlock>
      </div>
      <img src={logistik3Img} alt="Cad/Cam" className="logistik__img--1" />
      <img src={logistik4Img} alt="Cad/Cam" className="logistik__img--2" />
      <div className="logistik__textblock--2">
        <TextBlock title="Wir disponieren für Sie.">
          Heute bedeutet Logistik nicht mehr eine Ware von A nach B zu
          transportieren. Logistik stellt in der Produktionskette
          Effizienzpotential dar, um Kosten zu senken und Risiko zu minimieren.
          Längst schon disponieren wir für unsere Kunden, um Teile am richtigen
          Ort zur richtigen Zeit verfügbar zu halten.
        </TextBlock>
      </div>
      <div className="logistik__textblock--3">
        <TextBlock title="Wir bieten Lösungen.">
          Unabhängig davon, ob es sich um eine einmalige Bestellung, eine
          Serienbestellung, einen Bestellzyklus oder auch eine ständige
          Lieferbeziehung handelt, wir bieten die Logistik und die Lösungen für
          alle Arten der Belieferung mit Schmiederohlingen. Bei längerfristigen
          oder ständigen Lieferbeziehungen kann z. B. auch eine Bevorratung von
          Schmiederohlingen vereinbart werden, um die fortwährende und sofortige
          Verfügbarkeit sicher zu stellen.
        </TextBlock>
      </div>
      <img src={produkte3Img} alt="Cad/Cam" className="logistik__img--3" />
    </div>
  );
};
