import React, { useEffect } from "react";

import { Header } from "../Components/Header";
import { TextBlock } from "../Components/TextBlock";
import { Disclaimer } from "../Components/Disclaimer";
import produkte4Img from "../Assets/produkte4.png";
import produkte3Img from "../Assets/produkte3.png";
import produkte1Img from "../Assets/produkte1.png";

import "./Produkte.css";
import "./Produkte8.css";

const Produkte = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="produkte">
      <Header
        title="Stahl, Edelstahl, Messing und Sonderlegierungen in Präzision – seit Jahrzehnten unser Geschäft."
        image={produkte4Img}
        imageAlt={"Produkte"}
      />
      <div className="produkte__info">
        <div className="produkte__info__item--1--1">
          <TextBlock title="Schmiederohlinge für die Werkzeugindustrie.">
            Nicht nur als zuverlässiger Partner namhafter Fittinghersteller
            haben wir uns in der Branche einen Namen gemacht – wir liefern auch
            die unterschiedlichsten Schmiederohlinge an die Werkzeugindustrie
            oder an Unternehmen des Maschinenbaus. Wo auch immer präzise,
            hochbelastbare Schmiedeteile aus den unterschiedlichsten
            Materialsorten benötigt werden, beliefern wir unsere Kunden mit
            anforderungsgerechten Schmiedeteilen. Sonderlegierungen, ob nun auf
            Kupfer- oder Messingbasis, gehören für uns genauso zum Alltag wie
            die traditionellen Materialien.
          </TextBlock>
        </div>
        <div className="produkte__info__item--1--2">
          <img
            src={produkte3Img}
            alt="Produkte"
            className="produkte__info__item--1--2__img"
          />
        </div>
        <div className="produkte__info__item--2--1">
          <TextBlock title="Edelstahl - die hohe Schule des Schmiedens.">
            Die Verarbeitung von hochwertigen Edelstählen wie z. B. 1.4571 oder
            1.4301 zu hochfesten und korrosionsbeständigen Schmiedeteilen, das
            ist seit Jahrzehnten unsere Spezialität. Hier können wir die
            gewonnen Erfahrungen aus einer über 80 jährigen Schmiedetradition in
            Qualität umsetzen. Diese Erfahrung drückt sich in hoch entwickelten,
            fließgerechten Geometrien der Schmiedewerkzeuge aus. Das Ergebnis:
            Erzeugnisse, passgenau und rissfrei mit hervorragender
            Oberflächengüte.
          </TextBlock>
        </div>
        <div className="produkte__info__item--2--2">
          <TextBlock title="Stahl in exzellenter Qualität und Passgenauigkeit.">
            Ebenso wie beim Edelstahl erzeugen wir mit unseren hoch entwickelten
            und ständig weiter optimierten Fertigungswerkzeugen Schmiederohlinge
            in den verschiedensten Stahlsorten in exzellenter Qualität und
            Passgenauigkeit. Neben dem normalen Entzundern der Schmiedeteile
            mittels unserer drei Strahlanlagen bieten wir bei Edelstahlrohlingen
            auch die Oberfläche in gebeiztem Zustand an – ganz nach Kundenwunsch
            matt, in seidenglanz oder glänzend. Stahl in Perfektion ist dabei
            unser Anspruch, auf den sich unsere Geschäftspartner seit
            Jahrzehnten verlassen.
          </TextBlock>
        </div>
        <div className="produkte__info__item--3--1">
          <TextBlock title="Messing und Sonderlegierungen für extreme Anforderungen.">
            In hoch korrosivem Seeluftklima oder anderen speziellen
            Anforderungen kommen spezialisierte Sonderlegierungen auf Messing-
            oder Kupferbasis zum Einsatz. Auch diese Werkstoffe verarbeiten wir
            für unsere Kunden in etablierter Qualität, passgenau und mit
            vergleichbarer Oberflächengüte wie bei unseren Schmiedeteilen aus
            Stahl oder Edelstahl.
          </TextBlock>
        </div>
        <div className="produkte__info__item--3--2">
          <img
            src={produkte1Img}
            alt="Produkte"
            className="produkte__info__item--3--2__img"
          />
        </div>
      </div>
      <Disclaimer />
    </div>
  );
};

export default Produkte;
