import React, { useState, useEffect, useRef } from "react";

import { Disclaimer } from "../Components/Disclaimer";
import { TextBlock } from "../Components/TextBlock";
import { CAD } from "../Components/CAD";
import { Werkzeugbau } from "../Components/Werkzeugbau";
import { Fertigung } from "../Components/Fertigung";
import { Logistik } from "../Components/Logistik";

import "./Leistung.css";
import "./Leistung13.css";
import "./Leistung8.css";
import werkzeugbau2Img from "../Assets/werkzeugbau2.png";
import fertigung3Img from "../Assets/fertigung3.png";
import produkte2Img from "../Assets/produkte2.png";

const Leistung = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const buttonRef = useRef(null);
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop - 100);
  const [boxContent, setBoxContent] = useState(<CAD />);

  const [classes, setClasses] = useState({
    cad: [
      "leistung__details__buttons--unselected",
      "leistung__details__buttons--selected"
    ],
    werkzeugbau: ["leistung__details__buttons--unselected"],
    fertigung: ["leistung__details__buttons--unselected"],
    logistik: ["leistung__details__buttons--unselected"]
  });

  const switchBoxTo = content => {
    setClasses(() => {
      let newClasses = {
        cad: ["leistung__details__buttons--unselected"],
        werkzeugbau: ["leistung__details__buttons--unselected"],
        fertigung: ["leistung__details__buttons--unselected"],
        logistik: ["leistung__details__buttons--unselected"]
      };
      newClasses[content].push("leistung__details__buttons--selected");
      return newClasses;
    });
    console.log(classes);
    switch (content) {
      case "cad":
        setBoxContent(<CAD />);
        break;
      case "werkzeugbau":
        setBoxContent(<Werkzeugbau />);
        break;
      case "fertigung":
        setBoxContent(<Fertigung />);
        break;
      case "logistik":
        setBoxContent(<Logistik />);
        break;
      default:
        setBoxContent(<CAD />);
    }
  };

  return (
    <div className="leistung">
      <div className="leistung__header">
        <h3 className="leistung__header__h3">
          Große Flexibilität, kurze Lieferzeiten und die Erfüllung der
          Kundenwünsche sind für uns keine Lippenbekenntnisse, sondern gelebte
          Firmenphilosophie.
        </h3>
      </div>

      <div className="leistung__info">
        <div className="leistung__info__item--1--1">
          <TextBlock title="Von der Idee bis zum Bauteil.">
            Durch unsere eigene 3D-CAD Konstruktion und unseren modernen
            Werkzeugbau ermöglichen wir neben generell kurzen Lieferzeiten auch
            die zeitnahe Lieferung von Neuteilen. Selbstverständlich stehen wir
            auf Wunsch auch in Konstruktions- und Entwicklungsfragen beratend
            zur Seite. Dabei sehen wir uns als kooperativer Partner unserer
            Kunden, der mit seinem Wissen lösungsorientiert zum Projekterfolg
            beiträgt. Kompetente Ansprechpartner garantieren in jeder Phase
            einer Auftragsabwicklung die Erfüllung der Kundenanforderung bis hin
            zur zeitgerechten Lieferung.
          </TextBlock>
        </div>
        <div className="leistung__info__item--1--2">
          <img
            src={werkzeugbau2Img}
            alt="Werkzeugbau"
            className="leistung__info__item--1--2--1"
          />
          <img
            src={fertigung3Img}
            alt="Fertigung"
            className="leistung__info__item--1--2--2"
          />
          <img
            src={produkte2Img}
            alt="Produkte"
            className="leistung__info__item--1--2--3"
          />
        </div>
      </div>

      <div className="leistung__details">
        <div
          className="leistung__details__buttons"
          ref={buttonRef}
          onClick={() => scrollToRef(buttonRef)}
        >
          <span
            className={classes.cad.join(" ")}
            onClick={() => switchBoxTo("cad")}
          >
            CAD/CAM Entwicklung
          </span>
          <span
            className={classes.werkzeugbau.join(" ")}
            onClick={() => switchBoxTo("werkzeugbau")}
          >
            Werkzeugbau
          </span>
          <span
            className={classes.fertigung.join(" ")}
            onClick={() => switchBoxTo("fertigung")}
          >
            Fertigung
          </span>
          <span
            className={classes.logistik.join(" ")}
            onClick={() => switchBoxTo("logistik")}
          >
            Logistik
          </span>
        </div>
        <div className="leistung__details__box">{boxContent}</div>
      </div>
      <Disclaimer />
    </div>
  );
};

export default Leistung;
