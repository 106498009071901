import React, { useEffect } from "react";

import { TextBlock } from "../Components/TextBlock";
import { Disclaimer } from "../Components/Disclaimer";

import "./Datenschutz.css";
import "./Datenschutz13.css";
import "./Datenschutz8.css";

const Datenschutz = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="datenschutz">
      <div className="datenschutz__header">
        <h3 className="datenschutz__header__h3">Datenschutzerklärung</h3>
      </div>
      <div className="datenschutz__info">
        <div className="datenschutz__info__item">
          <TextBlock title="Datenschutzerklärung">
            Name und Kontakt des Verantwortlichen gemäß Artikel 4 Abs. 7 DSGVO
            <br />
            <br />
            Ernst Schäfer Nachf. GmbH
            <br />
            Van-Meenen-Str. 23
            <br />
            Gesenkschmiede
            <br />
            42651 Solingen
            <br />
          </TextBlock>
        </div>
        <div className="datenschutz__info__item">
          <TextBlock title="Sicherheit und Schutz Ihrer personenbezogenen Daten">
            Wir betrachten es als unsere vorrangige Aufgabe, die Vertraulichkeit
            der von Ihnen bereitgestellten personenbezogenen Daten zu wahren und
            diese vor unbefugten Zugriffen zu schützen. Deshalb wenden wir
            äußerste Sorgfalt und modernste Sicherheitsstandards an, um einen
            maximalen Schutz Ihrer personenbezogenen Daten zu gewährleisten. Als
            privatrechtliches Unternehmen unterliegen wir den Bestimmungen der
            europäischen Datenschutzgrundverordnung (DSGVO) und den Regelungen
            des Bundesdatenschutzgesetzes (BDSG). Wir haben technische und
            organisatorische Maß;nahmen getroffen, die sicherstellen, dass die
            Vorschriften über den Datenschutz sowohl von uns, als auch von
            unseren externen Dienstleistern beachtet werden.
            <br />
            <br />
            <strong>Begriffsbestimmungen</strong>
            <br />
            <br />
            Der Gesetzgeber fordert, dass personenbezogene Daten auf rechtmäßige
            Weise, nach Treu und Glauben und in einer für die betroffene Person
            nachvollziehbaren Weise verarbeitet werden (Rechtmäßigkeit,
            Verarbeitung nach Treu und Glauben, Transparenz). Um dies zu
            gewährleisten, informieren wir Sie über die einzelnen gesetzlichen
            Begriffsbestimmungen, die auch in dieser Datenschutzerklärung
            verwendet werden:
            <br />
            <br />
            <strong>1. Personenbezogene Daten</strong>
            <br />
            <br />
            Personenbezogene Daten sind alle Informationen, die sich auf eine
            identifizierte oder identifizierbare natürliche Person (im Folgenden
            betroffene Person) beziehen; als identifizierbar wird eine
            natürliche Person angesehen, die direkt oder indirekt, insbesondere
            mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
            Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem
            oder mehreren besonderen Merkmalen identifiziert werden kann, die
            Ausdruck der physischen, physiologischen, genetischen, psychischen,
            wirtschaftlichen, kulturellen oder sozialen Identität dieser
            natürlichen Person sind.
            <br />
            <br />
            <strong>2. Verarbeitung</strong>
            <br />
            <br />
            Verarbeitung ist jeder, mit oder ohne Hilfe automatisierter
            Verfahren, ausgeführter Vorgang oder jede solche Vorgangsreihe im
            Zusammenhang mit personenbezogenen Daten wie das Erheben, das
            Erfassen, die Organisation, das Ordnen, die Speicherung, die
            Anpassung oder Veränderung, das Auslesen, das Abfragen, die
            Verwendung, die Offenlegung durch übermittlung, Verbreitung oder
            eine andere Form der Bereitstellung, den Abgleich oder die
            Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
            <br />
            <br />
            <strong>3. Einschränkung der Verarbeitung</strong>
            <br />
            <br />
            Einschränkung der Verarbeitung ist die Markierung gespeicherter
            personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung
            einzuschränken.
            <br />
            <br />
            <strong>4. Profiling</strong>
            <br />
            <br />
            Profiling ist jede Art der automatisierten Verarbeitung
            personenbezogener Daten, die darin besteht, dass diese
            personenbezogenen Daten verwendet werden, um bestimmte persönliche
            Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
            insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche
            Lage, Gesundheit, persönliche Vorlieben, Interessen,
            Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser
            natürlichen Person zu analysieren oder vorherzusagen.
            <br />
            <br />
            <strong>5. Pseudonymisierung</strong>
            <br />
            <br />
            Pseudonymisierung ist die Verarbeitung personenbezogener Daten in
            einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung
            zusätzlicher Informationen nicht mehr einer spezifischen betroffenen
            Person zugeordnet werden können, sofern diese zusätzlichen
            Informationen gesondert aufbewahrt werden und technischen und
            organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die
            personenbezogenen Daten nicht einer identifizierten oder
            identifizierbaren natürlichen Person zugewiesen werden können.
            <br />
            <br />
            <strong>6. Dateisystem</strong>
            <br />
            <br />
            Dateisystem ist jede strukturierte Sammlung personenbezogener Daten,
            die nach bestimmten Kriterien zugänglich sind, unabhängig davon, ob
            diese Sammlung zentral, dezentral oder nach funktionalen oder
            geografischen Gesichtspunkten geordnet geführt wird.
            <br />
            <br />
            <strong>7. Verantwortlicher</strong>
            <br />
            <br />
            Verantwortlicher ist eine natürliche oder juristische Person,
            Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam
            mit anderen über die Zwecke und Mittel der Verarbeitung von
            personenbezogenen Daten entscheidet; sind die Zwecke und Mittel
            dieser Verarbeitung durch das Unionsrecht oder das Recht der
            Mitgliedstaaten vorgegeben, so können der Verantwortliche
            beziehungsweise die bestimmten Kriterien seiner Benennung nach dem
            Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.
            <br />
            <br />
            <strong>8. Auftragsverarbeiter</strong>
            <br />
            <br />
            Auftragsverarbeiter ist eine natürliche oder juristische Person,
            Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten
            im Auftrag des Verantwortlichen verarbeitet.
            <br />
            <br />
            <strong>9. Empfänger</strong>
            <br />
            <br />
            Empfänger ist eine natürliche oder juristische Person, Behörde,
            Einrichtung oder andere Stelle, denen personenbezogene Daten
            offengelegt werden, unabhängig davon, ob es sich bei ihr um einen
            Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten
            Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
            Mitgliedstaaten möglicherweise personenbezogene Daten erhalten,
            gelten jedoch nicht als Empfänger; die Verarbeitung dieser Daten
            durch die genannten Behörden erfolgt im Einklang mit den geltenden
            Datenschutzvorschriften gemäß den Zwecken der Verarbeitung.
            <br />
            <br />
            <strong>10. Dritter</strong>
            <br />
            <br />
            Dritter ist eine natürliche oder juristische Person, Behörde,
            Einrichtung oder andere Stelle, außer der betroffenen Person, dem
            Verantwortlichen, dem Auftragsverarbeiter und den Personen, die
            unter der unmittelbaren Verantwortung des Verantwortlichen oder des
            Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
            verarbeiten.
            <br />
            <br />
            <strong>11. Einwilligung</strong>
            <br />
            <br />
            Eine Einwilligung der betroffenen Person ist jede freiwillig für den
            bestimmten Fall, in informierter Weise und unmissverständlich
            abgegebene Willensbekundung in Form einer Erklärung oder einer
            sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene
            Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
            betreffenden personenbezogenen Daten einverstanden ist.
            <br />
            <br />
          </TextBlock>
        </div>
        <div className="datenschutz__info__item">
          <TextBlock title="Rechtmäßigkeit der Verarbeitung">
            Die Verarbeitung personenbezogener Daten ist nur rechtmäßig, wenn
            für die Verarbeitung eine Rechtsgrundlage besteht. Rechtsgrundlage
            für die Verarbeitung können gemäß Artikel 6 Abs. 1 lit. a – f DSGVO
            insbesondere sein: a. Die betroffene Person hat ihre Einwilligung zu
            der Verarbeitung der sie betreffenden personenbezogenen Daten für
            einen oder mehrere bestimmte Zwecke gegeben; b. die Verarbeitung ist
            für die Erfüllung eines Vertrags, dessen Vertragspartei die
            betroffene Person ist, oder zur Durchführung vorvertraglicher
            Maßnahmen erforderlich, die auf Anfrage der betroffenen Person
            erfolgen; c. die Verarbeitung ist zur Erfüllung einer rechtlichen
            Verpflichtung erforderlich, der der Verantwortliche unterliegt; d.
            die Verarbeitung ist erforderlich, um lebenswichtige Interessen der
            betroffenen Person oder einer anderen natürlichen Person zu
            schützen; e. die Verarbeitung ist für die Wahrnehmung einer Aufgabe
            erforderlich, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen
            wurde; f. die Verarbeitung ist zur Wahrung der berechtigten
            Interessen des Verantwortlichen oder eines Dritten erforderlich,
            sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der
            betroffenen Person, die den Schutz personenbezogener Daten
            erfordern, überwiegen, insbesondere dann, wenn es sich bei der
            betroffenen Person um ein Kind handelt.
          </TextBlock>
        </div>
        <div className="datenschutz__info__item">
          <TextBlock title="Information über die Erhebung personenbezogener Daten">
            (1) Im Folgenden informieren wir über die Erhebung personenbezogener
            Daten bei Nutzung unserer Website. Personenbezogene Daten sind z.B.
            Name, Adresse, E-Mail-Adressen, Nutzerverhalten.
            <br />
            <br />
            (2) Bei einer Kontaktaufnahme mit uns per E-Mail oder über ein
            Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre
            E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns
            gespeichert, um Ihre Fragen zu beantworten. Die in diesem
            Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung
            nicht mehr erforderlich ist, oder die Verarbeitung wird
            eingeschränkt, falls gesetzliche Aufbewahrungspflichten bestehen.)
            <br />
            <br />
            <strong>
              Erhebung personenbezogener Daten bei Besuch unserer Website
            </strong>
            <br />
            <br />
            Bei der bloß informatorischen Nutzung der Website, also wenn Sie
            sich nicht registrieren oder uns anderweitig Informationen
            übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr
            Browser an unseren Server übermittelt. Wenn Sie unsere Website
            betrachten möchten, erheben wir die folgenden Daten, die für uns
            technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und
            die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist
            Art. 6 Abs. 1 S. 1 lit. f DSGVO): – IP-Adresse – Datum und Uhrzeit
            der Anfrage – Zeitzonendifferenz zur Greenwich Mean Time (GMT) –
            Inhalt der Anforderung (konkrete Seite) –
            Zugriffsstatus/HTTP-Statuscode – jeweils übertragene Datenmenge –
            Website, von der die Anforderung kommt – Browser – Betriebssystem
            und dessen Oberfläche – Sprache und Version der Browsersoftware.
            <br />
            <br />
            <strong>Einsatz von Cookies</strong>
            <br />
            <br />
            (1) Zusätzlich zu den zuvor genannten Daten werden bei der Nutzung
            unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies
            handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem
            von Ihnen verwendeten Browser zugeordnet gespeichert werden und
            durch welche der Stelle, die den Cookie setzt, bestimmte
            Informationen zufließen. Cookies können keine Programme ausführen
            oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das
            Internetangebot insgesamt nutzerfreundlicher und effektiver zu
            machen.
            <br />
            <br />
            (2) Diese Website nutzt folgende Arten von Cookies, deren Umfang und
            Funktionsweise im Folgenden erläutert werden: – Transiente Cookies
            (dazu a.) – Persistente Cookies (dazu b.).
            <br />
            <br />
            a. Transiente Cookies werden automatisiert gelöscht, wenn Sie den
            Browser schließen. Dazu zählen insbesondere die Session-Cookies.
            Diese speichern eine sogenannte Session-ID, mit welcher sich
            verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung
            zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn
            Sie auf unsere Website zurückkehren. Die Session-Cookies werden
            gelöscht, wenn Sie sich ausloggen oder den Browser schließen.
            <br />
            <br />
            b. Persistente Cookies werden automatisiert nach einer vorgegebenen
            Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie
            können die Cookies in den Sicherheitseinstellungen Ihres Browsers
            jederzeit löschen.
            <br />
            <br />
            c. Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen
            konfigurieren und z. B. die Annahme von Third-Party-Cookies oder
            allen Cookies ablehnen. Sog. Third Party Cookies sind Cookies, die
            durch einen Dritten gesetzt wurden, folglich nicht durch die
            eigentliche Website auf der man sich gerade befindet. Wir weisen Sie
            darauf hin, dass Sie durch die Deaktivierung von Cookies eventuell
            nicht alle Funktionen dieser Website nutzen können.
            <br />
            <br />
            d. Wir setzen Cookies ein, um Sie für Folgebesuche identifizieren zu
            können, falls Sie über einen Account bei uns verfügen. Andernfalls
            müssten Sie sich für jeden Besuch erneut einloggen.
            <br />
            <br />
            e. Die genutzten Flash-Cookies werden nicht durch Ihren Browser
            erfasst, sondern durch Ihr Flash-Plug-in. Weiterhin nutzen wir HTML5
            storage objects, die auf Ihrem Endgerät abgelegt werden. Diese
            Objekte speichern die erforderlichen Daten unabhängig von Ihrem
            verwendeten Browser und haben kein automatisches Ablaufdatum. Wenn
            Sie keine Verarbeitung der Flash-Cookies wünschen, müssen Sie ein
            entsprechendes Add-On installieren, z.B. Better Privacy für Mozilla
            Firefox oder das Adobe-Flash-Killer-Cookie für Google Chrome. Die
            Nutzung von HTML5 storage objects können Sie verhindern, indem Sie
            in Ihrem Browser den privaten Modus einsetzen. Zudem empfehlen wir,
            regelmäßig Ihre Cookies und den Browser-Verlauf manuell zu löschen.
            <br />
            <br />
            <strong>Weitere Funktionen und Angebote unserer Website</strong>
            <br />
            <br />
            (1) Neben der rein informatorischen Nutzung unserer Website bieten
            wir verschiedene Leistungen an, die Sie bei Interesse nutzen können.
            Dazu müssen Sie in der Regel weitere personenbezogene Daten angeben,
            die wir zur Erbringung der jeweiligen Leistung nutzen und für die
            die zuvor genannten Grundsätze zur Datenverarbeitung gelten.
            <br />
            <br />
            (2) Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer
            Dienstleister. Diese wurden von uns sorgfältig ausgewählt und
            beauftragt, sind an unsere Weisungen gebunden und werden regelmäßig
            kontrolliert.
            <br />
            <br />
            (3) Weiterhin können wir Ihre personenbezogenen Daten an Dritte
            weitergeben, wenn Aktionsteilnahmen, Gewinnspiele,
            Vertragsabschlüsse oder ähnliche Leistungen von uns gemeinsam mit
            Partnern angeboten werden. Nähere Informationen hierzu erhalten Sie
            bei Angabe Ihrer personenbezogenen Daten oder untenstehend in der
            Beschreibung des Angebotes.
            <br />
            <br />
            (4) Soweit unsere Dienstleister oder Partner ihren Sitz in einem
            Staat außerhalb des Europäischen Wirtschaftsraumen (EWR) haben,
            informieren wir Sie über die Folgen dieses Umstands in der
            Beschreibung des Angebotes.
            <br />
            <br />
          </TextBlock>
        </div>
        <div className="datenschutz__info__item">
          <TextBlock title="Kinder">
            Unser Angebot richtet sich grundsätzlich an Erwachsene. Personen
            unter 18 Jahren sollten ohne Zustimmung der Eltern oder
            Erziehungsberechtigten keine personenbezogenen Daten an uns
            übermitteln.
          </TextBlock>
        </div>
        <div className="datenschutz__info__item">
          <TextBlock title="Rechte der betroffenen Person">
            <strong>(1) Widerruf der Einwilligung</strong>
            <br />
            <br />
            Sofern die Verarbeitung der personenbezogenen Daten auf einer
            erteilten Einwilligung beruht, haben Sie jederzeit das Recht, die
            Einwilligung zu widerrufen. Durch den Widerruf der Einwilligung wird
            die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
            erfolgten Verarbeitung nicht berührt. Für die Ausübung des
            Widerrufsrechts können Sie sich jederzeit an uns wenden.
            <br />
            <br />
            <strong>(2) Recht auf Bestätigung</strong>
            <br />
            <br />
            Sie haben das Recht, von dem Verantwortlichen eine Bestätigung
            darüber zu verlangen, ob wir sie betreffende personenbezogene Daten
            verarbeiten. Die Bestätigung können Sie jederzeit unter den oben
            genannten Kontaktdaten verlangen.
            <br />
            <br />
            <strong>(3) Auskunftsrecht</strong>
            <br />
            <br />
            Sofern personenbezogene Daten verarbeitet werden, können Sie
            jederzeit Auskunft über diese personenbezogenen Daten und über
            folgenden Informationen verlangen: a. die Verarbeitungszwecke;
            <br />
            <br />
            b. den Kategorien personenbezogener Daten, die verarbeitet werden;
            <br />
            <br />
            c. die Empfänger oder Kategorien von Empfängern, gegenüber denen die
            personenbezogenen Daten offengelegt worden sind oder noch
            offengelegt werden, insbesondere bei Empfängern in Drittländern oder
            bei internationalen Organisationen;
            <br />
            <br />
            d. falls möglich, die geplante Dauer, für die die personenbezogenen
            Daten gespeichert werden, oder, falls dies nicht möglich ist, die
            Kriterien für die Festlegung dieser Dauer;
            <br />
            <br />
            e. das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
            betreffenden personenbezogenen Daten oder auf Einschränkung der
            Verarbeitung durch den Verantwortlichen oder eines
            Widerspruchsrechts gegen diese Verarbeitung;
            <br />
            <br />
            f. das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
            <br />
            <br />
            g. wenn die personenbezogenen Daten nicht bei der betroffenen Person
            erhoben werden, alle verfügbaren Informationen über die Herkunft der
            Daten;
            <br />
            <br />
            h. das Bestehen einer automatisierten Entscheidungsfindung
            einschließlich Profiling gemäß Artikel 22 Absätze 1 und 4 DSGVO und
            – zumindest in diesen Fällen – aussagekräftige Informationen über
            die involvierte Logik sowie die Tragweite und die angestrebten
            Auswirkungen einer derartigen Verarbeitung für die betroffene
            Person.
            <br />
            <br />
            Werden personenbezogene Daten an ein Drittland oder an eine
            internationale Organisation übermittelt, so haben Sie das Recht,
            über die geeigneten Garantien gemäß Artikel 46 DSGVO im Zusammenhang
            mit der übermittlung unterrichtet zu werden. Wir stellen eine Kopie
            der personenbezogenen Daten, die Gegenstand der Verarbeitung sind,
            zur Verfügung. Für alle weiteren Kopien, die Sie Person beantragen,
            können wir ein angemessenes Entgelt auf der Grundlage der
            Verwaltungskosten verlangen. Stellen Sie den Antrag elektronisch, so
            sind die Informationen in einem gängigen elektronischen Format zur
            Verfügung zu stellen, sofern er nichts anderes angibt. Das Recht auf
            Erhalt einer Kopie gemäß Absatz 3 darf die Rechte und Freiheiten
            anderer Personen nicht beeinträchtigen.
            <br />
            <br />
            <strong>(4) Recht auf Berichtigung</strong>
            <br />
            <br />
            Sie haben das Recht, von uns unverzüglich die Berichtigung Sie
            betreffender unrichtiger personenbezogener Daten zu verlangen. Unter
            Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht,
            die Vervollständigung unvollständiger personenbezogener Daten – auch
            mittels einer ergänzenden Erklärung – zu verlangen.
            <br />
            <br />
            <strong>(5) Recht auf Löschung (Recht auf vergessen werden)</strong>
            <br />
            <br />
            Sie haben das Recht, von dem Verantwortlichen zu verlangen, dass Sie
            betreffende personenbezogene Daten unverzüglich gelöscht werden, und
            wir sind verpflichtet, personenbezogene Daten unverzüglich zu
            löschen, sofern einer der folgenden Gründe zutrifft:
            <br />
            <br />
            a. Die personenbezogenen Daten sind für die Zwecke, für die sie
            erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr
            notwendig.
            <br />
            <br />
            b. Die betroffene Person widerruft ihre Einwilligung, auf die sich
            die Verarbeitung gemäß Artikel 6 Absatz 1 Buchstabe a oder Artikel 9
            Absatz 2 Buchstabe a DSGVO stützte, und es fehlt an einer
            anderweitigen Rechtsgrundlage für die Verarbeitung.
            <br />
            <br />
            c. Die betroffene Person legt gemäß Artikel 21 Absatz 1 DSGVO
            Widerspruch gegen die Verarbeitung ein und es liegen keine
            vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die
            betroffene Person legt gemäß Artikel 21 Absatz 2 DSGVO Widerspruch
            gegen die Verarbeitung ein.
            <br />
            <br />
            d. Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
            <br />
            <br />
            e. Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
            rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
            Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
            <br />
            <br />
            f. Die personenbezogenen Daten wurden in Bezug auf angebotene
            Dienste der Informationsgesellschaft gemäß Artikel 8 Absatz 1 DSGVO
            erhoben. Hat der Verantwortliche die personenbezogenen Daten
            öffentlich gemacht und ist er gemäß Absatz 1 zu deren Löschung
            verpflichtet, so trifft er unter Berücksichtigung der verfügbaren
            Technologie und der Implementierungskosten angemessene Maßnahmen,
            auch technischer Art, um für die Datenverarbeitung Verantwortliche,
            die die personenbezogenen Daten verarbeiten, darüber zu informieren,
            dass eine betroffene Person von ihnen die Löschung aller Links zu
            diesen personenbezogenen Daten oder von Kopien oder Replikationen
            dieser personenbezogenen Daten verlangt hat. Das Recht auf Löschung
            (Recht auf vergessen werden) besteht nicht, soweit die Verarbeitung
            erforderlich ist: – zur Ausübung des Rechts auf freie
            Meinungsäußerung und Information; – zur Erfüllung einer rechtlichen
            Verpflichtung, die die Verarbeitung nach dem Recht der Union oder
            der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert,
            oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse
            liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem
            Verantwortlichen übertragen wurde; – aus Gründen des öffentlichen
            Interesses im Bereich der öffentlichen Gesundheit gemäß Artikel 9
            Absatz 2 Buchstaben h und i sowie Artikel 9 Absatz 3 DSGVO; – für im
            öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder
            historische Forschungszwecke oder für statistische Zwecke gemäß
            Artikel 89 Absatz 1 DSGVO, soweit das in Absatz 1 genannte Recht
            voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung
            unmöglich macht oder ernsthaft beeinträchtigt, oder – zur
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            <br />
            <br />
            <strong>(6) Recht auf Einschränkung der Verarbeitung</strong>
            <br />
            <br />
            Sie haben das Recht, von uns die Einschränkung der Verarbeitung
            ihrer personenbezogenen Daten zu verlangen, wenn eine der folgenden
            Voraussetzungen gegeben ist: a. die Richtigkeit der
            personenbezogenen Daten von der betroffenen Person bestritten wird,
            und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die
            Richtigkeit der personenbezogenen Daten zu überprüfen, b. die
            Verarbeitung unrechtmäßig ist und die betroffene Person die Löschung
            der personenbezogenen Daten ablehnt und stattdessen die
            Einschränkung der Nutzung der personenbezogenen Daten verlangt; c.
            der Verantwortliche die personenbezogenen Daten für die Zwecke der
            Verarbeitung nicht länger benötigt, die betroffene Person sie jedoch
            zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            benötigt, oder d. die betroffene Person Widerspruch gegen die
            Verarbeitung gemäß Artikel 21 Absatz 1 DSGVO eingelegt hat, solange
            noch nicht feststeht, ob die berechtigten Gründe des
            Verantwortlichen gegenüber denen der betroffenen Person überwiegen.
            Wurde die Verarbeitung gemäß den oben genannten Voraussetzungen
            eingeschränkt, so werden diese personenbezogenen Daten – von ihrer
            Speicherung abgesehen – nur mit Einwilligung der betroffenen Person
            oder zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen oder zum Schutz der Rechte einer anderen
            natürlichen oder juristischen Person oder aus Gründen eines
            wichtigen öffentlichen Interesses der Union oder eines
            Mitgliedstaats verarbeitet. Um das Recht auf Einschränkung der
            Verarbeitung geltend zu machen, kann sich die betroffene Person
            jederzeit an uns unter den oben angegebenen Kontaktdaten wenden.
            <br />
            <br />
            <strong>(7) Recht auf Datenübertragbarkeit</strong>
            <br />
            <br />
            Sie haben das Recht, die Sie betreffenden personenbezogenen Daten,
            die Sie uns bereitgestellt haben, in einem strukturierten, gängigen
            und maschinenlesbaren Format zu erhalten, und Sie haben das Recht,
            diese Daten einem anderen Verantwortlichen ohne Behinderung durch
            den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt
            wurden, zu übermitteln, sofern: a. die Verarbeitung auf einer
            Einwilligung gemäß Artikel 6 Absatz 1 Buchstabe a oder Artikel 9
            Absatz 2 Buchstabe a oder auf einem Vertrag gemäß Artikel 6 Absatz 1
            Buchstabe b DSGVO beruht und b. die Verarbeitung mithilfe
            automatisierter Verfahren erfolgt. Bei der Ausübung des Rechts auf
            Datenübertragbarkeit gemäß Absatz 1 haben Sie das Recht, zu
            erwirken, dass die personenbezogenen Daten direkt von einem
            Verantwortlichen zu einem anderen Verantwortlichen übermittelt
            werden, soweit dies technisch machbar ist. Die Ausübung des Rechts
            auf Datenübertragbarkeit lässt das Recht auf Löschung (Recht auf
            Vergessen werden) unberührt. Dieses Recht gilt nicht für eine
            Verarbeitung, die für die Wahrnehmung einer Aufgabe erforderlich
            ist, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen
            wurde.
            <br />
            <br />
            <strong>(8) Widerspruchsrecht</strong>
            <br />
            <br />
            Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
            Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
            personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1
            Buchstaben e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt
            auch für ein auf diese Bestimmungen gestütztes Profiling. Der
            Verantwortliche verarbeitet die personenbezogenen Daten nicht mehr,
            es sei denn, er kann zwingende schutzwürdige Gründe für die
            Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten
            der betroffenen Person überwiegen, oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            Werden personenbezogene Daten verarbeitet, um Direktwerbung zu
            betreiben, so haben SIe das Recht, jederzeit Widerspruch gegen die
            Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
            derartiger Werbung einzulegen; dies gilt auch für das Profiling,
            soweit es mit solcher Direktwerbung in Verbindung steht.
            Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so
            werden die personenbezogenen Daten nicht mehr für diese Zwecke
            verarbeitet. Im Zusammenhang mit der Nutzung von Diensten der
            Informationsgesellschaft könne Sie ungeachtet der Richtlinie
            2002/58/EG Ihr Widerspruchsrecht mittels automatisierter Verfahren
            ausüben, bei denen technische Spezifikationen verwendet werden. Sie
            haben das Recht, aus Gründen, die sich aus Ihrer besonderen
            Situation ergeben, gegen die Sie betreffende Verarbeitung Sie
            betreffender personenbezogener Daten, die zu wissenschaftlichen oder
            historischen Forschungszwecken oder zu statistischen Zwecken gemäß
            Artikel 89 Absatz 1 erfolgt, Widerspruch einzulegen, es sei denn,
            die Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse
            liegenden Aufgabe erforderlich. Das Widerspruchsrecht können Sie
            jederzeit ausüben, indem Sie sich an den jeweiligen Verantwortlichen
            wenden.
            <br />
            <br />
            <strong>
              (9) Automatisierte Entscheidungen im Einzelfall einschließlich
              Profiling
            </strong>
            <br />
            <br />
            Sie haben das Recht, nicht einer ausschließlich auf einer
            automatisierten Verarbeitung – einschließlich Profiling – beruhenden
            Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
            Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
            beeinträchtigt. Dies gilt nicht, wenn die Entscheidung: a. für den
            Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen
            Person und dem Verantwortlichen erforderlich ist, b. aufgrund von
            Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
            Verantwortliche unterliegt, zulässig ist und diese
            Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und
            Freiheiten sowie der berechtigten Interessen der betroffenen Person
            enthalten oder c. mit ausdrücklicher Einwilligung der betroffenen
            Person erfolgt. Der Verantwortliche trifft angemessene Maßnahmen, um
            die Rechte und Freiheiten sowie die berechtigten Interessen der
            betroffenen Person zu wahren, wozu mindestens das Recht auf
            Erwirkung des Eingreifens einer Person seitens des Verantwortlichen,
            auf Darlegung des eigenen Standpunkts und auf Anfechtung der
            Entscheidung gehört. Dieses Recht kann die betroffene Person
            jederzeit ausüben, indem sie sich an den jeweiligen Verantwortlichen
            wendet.
            <br />
            <br />
            <strong>
              (10) Recht auf Beschwerde bei einer Aufsichtsbehörde
            </strong>
            <br />
            <br />
            Sie haben zudem, unbeschadet eines anderweitigen
            verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, das Recht
            auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des
            Orts des mutmaßlichen Verstoßes, wenn die betroffene Person der
            Ansicht ist, dass die Verarbeitung der sie betreffenden
            personenbezogenen Daten gegen diese Verordnung verstößt.
            <br />
            <br />
            <strong>(11) Recht auf wirksamen gerichtlichen Rechtsbehelf</strong>
            <br />
            <br />
            Sie haben unbeschadet eines verfügbaren verwaltungsrechtlichen oder
            außergerichtlichen Rechtsbehelfs einschließlich des Rechts auf
            Beschwerde bei einer Aufsichtsbehörde gemäß Artikel 77 DSGVO das
            Recht auf einen wirksamen gerichtlichen Rechtsbehelf, wenn sie der
            Ansicht ist, dass die ihr aufgrund dieser Verordnung zustehenden
            Rechte infolge einer nicht im Einklang mit dieser Verordnung
            stehenden Verarbeitung ihrer personenbezogenen Daten verletzt
            wurden.
            <br />
            <br />
          </TextBlock>
        </div>
        <div className="datenschutz__info__item">
          <TextBlock title="Einsatz von Google Analytics">
            (1) Diese Website benutzt Google Analytics, einen Webanalysedienst
            der Google Inc. (Google). Google Analytics verwendet sog. Cookies,
            Textdateien, die auf Ihrem Computer gespeichert werden und die eine
            Analyse der Benutzung der Website durch Sie ermöglichen. Die durch
            den Cookie erzeugten Informationen über Ihre Benutzung dieser
            Website werden in der Regel an einen Server von Google in den USA
            übertragen und dort gespeichert. Im Falle der Aktivierung der
            IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von
            Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
            oder in anderen Vertragsstaaten des Abkommens über den Europäischen
            Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
            IP-Adresse an einen Server von Google in den USA übertragen und dort
            gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
            Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
            Reports über die Website-Aktivitäten zusammenzustellen und um
            weitere mit der Website-Nutzung und der Internetnutzung verbundene
            Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.
            <br />
            <br />
            (2) Die im Rahmen von Google Analytics von Ihrem Browser
            übermittelte IP-Adresse wird nicht mit anderen Daten von Google
            zusammengeführt.
            <br />
            <br />
            (3) Sie können die Speicherung der Cookies durch eine entsprechende
            Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können. Sie
            können darüber hinaus die Erfassung der durch das Cookie erzeugten
            und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
            IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
            Google verhindern, indem sie das unter dem folgenden Link verfügbare
            Browser-Plug-in herunterladen und installieren:{" "}
            <a
              href="http://tools.google.com/dlpage/gaoptout?hl=de"
              className="datenschutz__link"
            >
              http://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            .
            <br />
            <br />
            (4) Diese Website verwendet Google Analytics mit der Erweiterung
            _anonymizeIp(). Dadurch werden IP-Adressen gekürzt
            weiterverarbeitet, eine Personenbeziehbarkeit kann damit
            ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein
            Personenbezug zukommt, wird dieser also sofort ausgeschlossen und
            die personenbezogenen Daten damit umgehend gelöscht.
            <br />
            <br />
            (5) Wir nutzen Google Analytics, um die Nutzung unserer Website
            analysieren und regelmäßig verbessern zu können. über die gewonnenen
            Statistiken können wir unser Angebot verbessern und für Sie als
            Nutzer interessanter ausgestalten. Für die Ausnahmefälle, in denen
            personenbezogene Daten in die USA übertragen werden, hat sich Google
            dem EU-US Privacy Shield unterworfen,{" "}
            <a
              href="https://www.privacyshield.gov/EU-US-Framework"
              className="datenschutz__link"
            >
              https://www.privacyshield.gov/EU-US-Framework
            </a>
            . Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6
            Abs. 1 S. 1 lit. f DSGVO.
            <br />
            <br />
            (6) Informationen des Drittanbieters: Google Dublin, Google Ireland
            Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1)
            436 1001. Nutzerbedingungen:{" "}
            <a
              href="http://www.google.com/analytics/terms/de.html"
              className="datenschutz__link"
            >
              http://www.google.com/analytics/terms/de.html
            </a>
            , Übersicht zum Datenschutz:{" "}
            <a
              href="http://www.google.com/intl/de/analytics/learn/privacy.html"
              className="datenschutz__link"
            >
              http://www.google.com/intl/de/analytics/learn/privacy.html
            </a>
            , sowie die Datenschutzerklärung:{" "}
            <a
              href="http://www.google.de/intl/de/policies/privacy"
              className="datenschutz__link"
            >
              http://www.google.de/intl/de/policies/privacy
            </a>
            .
            <br />
            <br />
            (7) Diese Website verwendet Google Analytics zudem für eine
            geräteübergreifende Analyse von Besucherströmen, die über eine
            User-ID durchgeführt wird. Sie können in Ihrem Kundenkonto unter
            Meine Daten, persönliche Daten, die geräteübergreifende Analyse
            Ihrer Nutzung deaktivieren.
          </TextBlock>
        </div>
      </div>
      <Disclaimer />
    </div>
  );
};

export default Datenschutz;
